import { State, ResultItem, MobileFilterScrollPositions, Results } from "./types";
import { Filter, FilterGroup, FilterSection, FilterSections } from "@/components/organisms/filter-multi/types";

import { intersection } from "lodash";

import { getResultCountForSingleFilter } from "../../helpers/getResultCountForSingleFilter";
import { sortResults } from "../../helpers/sortResults";
import { getResultGroups } from "../../helpers/getResultGroups";

export const state: State = {
    filterInitialized: false,
    filterTeaserCounts: 0,
    filterTeasersLoaded: false,

    loadedFilterTeasers: 0,

    availableResults: [],
    availableResultsCount: 0,

    filteredResults: [],
    filteredResultsCount: 0,

    totalResultGroups: {},

    availableFilterGroups: {},

    activeFilters: [],

    loading: true,

    mobileFiltersOpen: false,
    mobileFiltersOpenedWithGroup: "",
    mobileFiltersGroupScrollPositions: {},

    orderValue: 1,

    sortingValue: 0,

    SORTING_MAP: {
        0: "filterResults",
        1: "title",
        2: "timestamp",
        3: "popularity",
    },

    selectedRecordingFilters: [],

    filterMode: "AND",
};

export const namespaced = true;

export const getters: any = {
    getFilterInitialized: (state: State) => {
        return state.filterInitialized;
    },

    getFilterTeaserCounts: (state: State) => {
        return state.filterTeaserCounts;
    },

    getFilterTeasersLoaded: (state: State) => {
        return state.filterTeasersLoaded;
    },

    getLoadedFilterTeasers: (state: State) => {
        return state.loadedFilterTeasers;
    },

    getAvailableResults: (state: State) => {
        return state.availableResults;
    },

    getAvailableResultsCount: (state: State) => {
        return state.availableResults.length;
    },

    getFilteredResults: (state: State) => {
        return state.filteredResults;
    },

    getFilteredResultsCount: (state: State) => {
        return state.filteredResults.length;
    },

    getAvailableFilterGroups: (state: State) => {
        return state.availableFilterGroups;
    },

    getActiveFilters: (state: State) => {
        return state.activeFilters;
    },

    getFilterGroupsFromResultItem: (state: State) => (resultItem: ResultItem) => {
        let filterGroups: (false | string)[] = [];
        const filters = Object.values(state.availableFilterGroups)[0];

        resultItem.filterterms.forEach((filterTerm: string) => {
            filterGroups = filters.groups
                .map((filterGroup: FilterGroup) => {
                    const hasFilterTerm = filterGroup.filters.some((filter) => {
                        return filter.value === filterTerm;
                    });

                    if (hasFilterTerm && filterGroups.indexOf(filterGroup.id) < 0) {
                        return filterGroup.id;
                    } else {
                        return false;
                    }
                })
                .concat(filterGroups)
                .filter((filterId: string | boolean) => {
                    return typeof filterId === "string";
                });
        });

        return filterGroups;
    },

    getFilterTermFromFilterGroupId: (state: State) => (filterGroupId: string, filterTerms: string[]) => {
        if (!filterTerms) return;

        const filters = Object.values(state.availableFilterGroups)[0];

        const filterGroup = filters.groups.find((group: FilterGroup) => {
            return group.id === filterGroupId;
        });

        if (!filterGroup) return;

        return filterTerms.filter((filterTerm) => {
            const filteredTerms = filterGroup.filters.filter((filter) => {
                return filter.value === filterTerm;
            });

            if (!filteredTerms.length) return false;

            return filteredTerms.filter((filter) => {
                return filter.value === filterTerm;
            });
        });
    },

    getSectionFromFilterTerm: (state: State) => (filterTerm: string) => {
        return Object.values(state.availableFilterGroups).find((filterSection) => {
            return filterSection.groups.find((group: FilterGroup) => {
                return group.filters.find((filter) => {
                    return filter.value === filterTerm;
                });
            });
        });
    },

    getFilterName: (state: State, getters: any) => (filterTerm: string) => {
        return getters
            .getSectionFromFilterTerm(filterTerm)
            .groups.find((group: FilterGroup) => {
                return group.filters.find((filter) => {
                    return filter.value === filterTerm;
                });
            })
            .filters.find((filter: Filter) => {
                return filter.value === filterTerm;
            }).name;
    },

    getFilterNames: (state: State, getters: any) => (filterTerms: string[]) => {
        const filterNames: string[] = [];

        filterTerms.forEach((filterTerm) => {
            filterNames.push(getters.getFilterName(filterTerm));
        });

        return filterNames.join(", ");
    },

    getGroupFromFilterTerm: (state: State, getters: any) => (filterTerm: String) => {
        return getters.getSectionFromFilterTerm(filterTerm).groups.find((group: FilterGroup) => {
            return group.filters.find((filter) => {
                return filter.value === filterTerm;
            });
        });
    },

    getGroupIdFromFilterTerm: (state: State, getters: any) => (filterTerm: string) => {
        return getters.getGroupFromFilterTerm(filterTerm).id;
    },

    loading: (state: State) => {
        return state.loading;
    },

    getIntersectionFromResultGroups(state: State) {
        const resultGroups = [...Object.values(state.totalResultGroups)];
        let results: ResultItem[] = [];

        resultGroups.sort((groupA, groupB) => {
            return groupA.length > groupB.length ? -1 : 1;
        });

        resultGroups.forEach((resultGroup) => {
            if (!resultGroup.length) return;

            if (!results.length) {
                results = [...resultGroup];
            } else {
                results = intersection(results, resultGroup);
            }
        });

        return results;
    },

    getFilterSectionByGroup: (state: State) => (groupId: string) => {
        return Object.values(state.availableFilterGroups).find((filterSection) => {
            return filterSection.groups.find((group: any) => {
                return group.id === groupId;
            });
        });
    },

    getSelectedFiltersInGroupCount: (state: State, getters: any) => (groupId: number) => {
        const currentSection = getters.getFilterSectionByGroup(groupId);

        const currentGroup = currentSection.groups.find((group: any) => {
            return group.id === groupId;
        });

        if (currentGroup) {
            return currentGroup.filters.filter((filter: any) => {
                return state.activeFilters
                    .map((activeFilter: any) => {
                        return activeFilter === filter.value;
                    })
                    .some((active: boolean) => {
                        return active;
                    });
            }).length;
        } else {
            return 0;
        }
    },

    getSelectedFiltersInSectionCount: (state: State, getters: any) => (section: any) => {
        let count = 0;

        section.groups.forEach((group: any) => {
            count += getters.getSelectedFiltersInGroupCount(group.id);
        });

        return count;
    },

    getFilterGroupFromSectionByGroupId: () => (filterSection: FilterSection, groupId: string) => {
        return filterSection.groups.find((group) => {
            return group.id === groupId;
        });
    },

    getFilterSectionByGroupId: (state: State, getters: any) => (groupId: string) => {
        return Object.values(state.availableFilterGroups).find((section) => {
            const filterGroup = getters.getFilterGroupFromSectionByGroupId(section, groupId);

            if (!filterGroup) return false;

            return filterGroup.length > 0;
        });
    },

    getGroupResultCounts: (state: State, getters: any) => async (groupId: string) => {
        let count = 0;

        const filterSection = await getters.getFilterSectionByGroupId(groupId);

        if (!filterSection) return count;

        const filterGroup = getters.getFilterGroupFromSectionByGroupId(filterSection, groupId);

        filterGroup.filters.forEach((filter: Filter) => {
            count += getters.getSingleFilterResultCounts(filter.value);
        });

        return count;
    },

    getSingleFilterResultCounts: (state: State) => (filterTerm: string) => {
        if (state.filterMode === "OR") {
            return getResultCountForSingleFilter({ filterResults: state.availableResults, filterTerm });
        } else {
            return getResultCountForSingleFilter({ filterResults: state.filteredResults, filterTerm });
        }
    },

    getMobileFiltersOpen: (state: State) => {
        return state.mobileFiltersOpen;
    },

    getMobileFiltersOpenedWithGroup: (state: State) => {
        return state.mobileFiltersOpenedWithGroup;
    },

    getMobileFiltersGroupScrollPositions: (state: State) => {
        return state.mobileFiltersGroupScrollPositions;
    },

    getMobileFiltersGroupScrollPosition: (state: State) => (groupId: string) => {
        if (groupId in state.mobileFiltersGroupScrollPositions) {
            return state.mobileFiltersGroupScrollPositions[groupId];
        } else {
            return false;
        }
    },

    getSortingValue: (state: State) => {
        return state.sortingValue;
    },

    getOrderValue: (state: State) => {
        return state.orderValue;
    },

    getFilterMode: (state: State) => {
        return state.filterMode;
    },

    selectedRecordingFilters: (state: State): string[] => {
        return state.selectedRecordingFilters;
    },
};

export const mutations: any = {
    SET_FILTER_INITIALIZED(state: State, isInitialized: boolean) {
        state.filterInitialized = isInitialized;
    },

    SET_FILTER_TEASERS_LOADED(state: State, loaded: boolean) {
        state.filterTeasersLoaded = loaded;
    },

    SET_FILTER_TEASER_COUNT(state: State, count: number) {
        state.filterTeaserCounts = count;
    },

    SET_LOADED_FILTER_TEASERS(state: State, count: number) {
        state.loadedFilterTeasers = count;
    },

    SET_AVAILABLE_RESULTS(state: State, results: Results) {
        state.availableResults = results;
    },

    SET_FILTERED_RESULTS(state: State, results: Results) {
        state.filteredResults = results;
    },

    SET_AVAILABLE_FILTER_GROUPS(state: State, filterGroups: FilterSections) {
        state.availableFilterGroups = filterGroups;
    },

    SET_ACTIVE_FILTERS(state: State, searchTerms: string[]) {
        state.activeFilters = searchTerms;
    },

    SET_TOTAL_RESULT_GROUPS(state: State, totalResultGroups: object) {
        state.totalResultGroups = totalResultGroups;
    },

    SET_LOADING(state: State, loading: boolean) {
        state.loading = loading;
    },

    SET_MOBILE_FILTERS_OPEN(state: State, isOpen: boolean) {
        state.mobileFiltersOpen = isOpen;
    },

    SET_MOBILE_FILTERS_OPEN_WITH_GROUP(state: State, filterGroup: string) {
        state.mobileFiltersOpenedWithGroup = filterGroup;
    },

    SET_MOBILE_FILTERS_GROUP_SCROLL_POSITIONS(state: State, scrollPositions: MobileFilterScrollPositions) {
        state.mobileFiltersGroupScrollPositions = scrollPositions;
    },

    SET_SORTING_VALUE(state: State, sortingValue: number) {
        state.sortingValue = sortingValue;
    },

    SET_ORDER_VALUE(state: State, orderValue: number) {
        state.orderValue = orderValue;
    },

    SET_FILTER_MODE(state: State, filterMode: string) {
        state.filterMode = filterMode;
    },

    SET_RECORDING_FILTERS: function (state: State, filterTopic: string) {
        const currentFilters = Object.assign([], state.selectedRecordingFilters);

        if (currentFilters.includes(filterTopic)) {
            currentFilters.splice(currentFilters.indexOf(filterTopic), 1);
        } else {
            currentFilters.push(filterTopic);
        }

        state.selectedRecordingFilters = currentFilters;
    },

    CLEAR_RECORDING_FILTERS: function (state: State, filters: string[]) {
        state.selectedRecordingFilters = filters;
    },
};

export const actions: any = {
    setFilterInitialized({ commit }: any, isInitialized: boolean) {
        commit("SET_FILTER_INITIALIZED", isInitialized);
    },

    setFilterTeasersLoaded({ commit }: any, loaded: boolean) {
        commit("SET_FILTER_TEASERS_LOADED", loaded);
    },

    setFilterTeaserCount({ commit }: any, count: number) {
        commit("SET_FILTER_TEASER_COUNT", count);
    },

    setLoadedFilterTeasers({ commit }: any, count: number) {
        commit("SET_LOADED_FILTER_TEASERS", count);
    },

    setAvailableResults({ commit }: any, results: Results) {
        commit("SET_AVAILABLE_RESULTS", results);
    },

    setFilteredResults({ commit }: any, filteredResults: Results) {
        commit("SET_FILTERED_RESULTS", filteredResults);
    },

    setAvailableFilterGroups({ commit }: any, filterGroups: object) {
        commit("SET_AVAILABLE_FILTER_GROUPS", filterGroups);
    },

    setActiveFilters({ commit }: any, searchTerms: string[]) {
        commit("SET_ACTIVE_FILTERS", searchTerms);
    },

    setLoading({ commit }: any, loading: boolean) {
        commit("SET_LOADING", loading);
    },

    initFilterTeaserGroups({ commit }: any) {
        commit("SET_LOADED_FILTER_TEASERS", 0);
        commit("SET_FILTER_TEASERS_LOADED", false);
    },

    sortResultsIntoGroups({ state, getters, commit }: any): Promise<void> {
        return new Promise((resolve) => {
            const filteredResults = state.availableResults.filter((result: ResultItem) => {
                const resultHasTerm = (term: string) => result.filterterms.includes(term);

                return state.activeFilters.some(resultHasTerm);
            });

            //Create new empty resultGroups
            const totalResultGroups = getResultGroups(getters.getAvailableFilterGroups);

            filteredResults.forEach((result: ResultItem) => {
                result.filterterms.forEach((filterterm) => {
                    if (!totalResultGroups || !state.activeFilters.includes(filterterm)) return;

                    if (!totalResultGroups[getters.getGroupIdFromFilterTerm(filterterm)].includes(result)) {
                        totalResultGroups[getters.getGroupIdFromFilterTerm(filterterm)].push(result);
                    }
                });
            });

            commit("SET_TOTAL_RESULT_GROUPS", totalResultGroups);

            resolve();
        });
    },

    filterResults({ state, getters, commit, dispatch }: any): Promise<void> {
        return new Promise((resolve) => {
            if (state.activeFilters && state.activeFilters.length) {
                if (state.filterMode === "OR") {
                    dispatch("sortResultsIntoGroups");

                    commit("SET_FILTERED_RESULTS", getters.getIntersectionFromResultGroups);
                } else {
                    const filteredResults = state.availableResults.filter((result: ResultItem) => {
                        const resultHasTerm = (term: string) => result.filterterms.indexOf(term) > -1;

                        return state.activeFilters.every(resultHasTerm);
                    });

                    commit("SET_FILTERED_RESULTS", filteredResults);
                }
            } else {
                const results = state.availableResults.slice();

                commit("SET_FILTERED_RESULTS", results);
            }

            resolve();
        });
    },

    sortFilterResultsByValue({ state, commit }: any, sortingValue: number) {
        commit("SET_FILTERED_RESULTS", sortResults({ filterResults: state.filteredResults.slice(), sortingValue }));
    },

    orderFilterResults({ state, commit }: any) {
        commit("SET_FILTERED_RESULTS", [...state.filteredResults].reverse());
    },

    setupFilter({ commit }: any, { searchTerms, availableResults, availableFilters }: any) {
        return new Promise((resolve) => {
            const results = availableResults.slice();
            const filters = Object.assign({}, availableFilters);

            commit("SET_AVAILABLE_RESULTS", results);
            commit("SET_AVAILABLE_FILTER_GROUPS", filters);
            commit("SET_ACTIVE_FILTERS", searchTerms);

            resolve(true);
        });
    },

    async initFilter({ dispatch }: any, { searchTerms, availableResults, availableFilters }: any) {
        await dispatch("setupFilter", { searchTerms, availableResults, availableFilters });
        await dispatch("filterResults");
    },

    increaseLoadedFilterTeasers({ state, commit }: any) {
        commit("SET_LOADED_FILTER_TEASERS", state.loadedFilterTeasers + 1);
        commit("SET_FILTER_TEASERS_LOADED", state.filterTeaserCounts === state.loadedFilterTeasers);
    },

    updateActiveFilters({ state, commit }: any, { searchTerm }: any) {
        const activeFilters = state.activeFilters.slice();
        const searchTermIndex = activeFilters.indexOf(searchTerm);

        if (searchTermIndex > -1) {
            activeFilters.splice(searchTermIndex, 1);
        } else {
            activeFilters.push(searchTerm);
        }

        commit("SET_ACTIVE_FILTERS", activeFilters);
    },

    clearFilters({ commit, getters }: any) {
        commit("SET_ACTIVE_FILTERS", []);
        commit("SET_TOTAL_RESULT_GROUPS", getResultGroups(getters.getAvailableFilterGroups));
    },

    clearGroupFilters({ state, getters, commit }: any, groupId: string) {
        let activeFilters = state.activeFilters.slice();

        const groupFilters = getters.getFilterSectionByGroup(groupId).groups.find((group: any) => {
            return group.id === groupId;
        }).filters;

        const groupFilterValues = groupFilters.map((groupFilter: any) => {
            return groupFilter.value;
        });

        activeFilters = activeFilters.filter((filter: string) => {
            return groupFilterValues.indexOf(filter) < 0;
        });

        commit("SET_ACTIVE_FILTERS", activeFilters);
    },

    openMobileFilters({ commit }: any, { isOpen, filterGroup = "" }: any) {
        if (filterGroup.length) {
            commit("SET_MOBILE_FILTERS_OPEN_WITH_GROUP", filterGroup);
        }

        commit("SET_MOBILE_FILTERS_OPEN", isOpen);
    },

    setMobileFiltersGroupScrollPosition({ state, commit }: any, { groupId, scrollPosition }: any) {
        const scrollPositions = Object.assign({}, state.mobileFiltersGroupScrollPositions);
        scrollPositions[groupId] = scrollPosition;

        commit("SET_MOBILE_FILTERS_GROUP_SCROLL_POSITIONS", scrollPositions);
    },

    closeMobileFilters({ commit }: any) {
        commit("SET_MOBILE_FILTERS_OPEN_WITH_GROUP", "");
        commit("SET_MOBILE_FILTERS_OPEN", false);
    },

    sortResults({ state, commit, dispatch }: any, sortingValue: number) {
        commit("SET_SORTING_VALUE", sortingValue);
        dispatch("sortFilterResultsByValue", state.SORTING_MAP[sortingValue]);
    },

    orderResults({ state, commit, dispatch }: any, orderValue: number) {
        commit("SET_ORDER_VALUE", orderValue);
        dispatch("orderFilterResults");
    },

    setFilterMode({ commit }: any, filterMode: string) {
        commit("SET_FILTER_MODE", filterMode);
    },

    setRecordingFilter({ commit }: any, filterTopic: string) {
        commit("SET_RECORDING_FILTERS", filterTopic);
    },

    clearRecordingFilter({ commit }: any, filters: string[]) {
        commit("CLEAR_RECORDING_FILTERS", filters);
    },
};
